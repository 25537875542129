import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './reviews.module.css'
import Layout from '../components/layout'
import LoanPreview from '../components/loan-preview'
import "../assets/main.css";
import { Link } from 'gatsby'
import SideContent from '../components/side-components/article-side.js'

class BusinessLoan extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulLoan.edges')
    const articles = get(this, 'props.data.allContentfulArticle.edges')

    const mainContent = (
        <div className="wrapper">
            {/*<h2 className="section-headline text-center">Small Business Loans</h2>*/}
         
         <div className="wrapper">
            <div className="flex inline-flex grid sm:grid-cols-1 md:grid-cols-2 m-0">
              {posts.map(({ node }) => {
                return (
                  <div class="max-w-3xl sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl rounded overflow-hidden shadow-lg grid-flow-col bg-white m-2 relative">
                    <div class="px-6 py-4">
                        <div class="font-bold text-xl mb-2">{node.title}</div>
                            <p class="text-gray-700 text-base p-4 pt-0">
                                {node.description.description}
                            </p>
                        </div>
                    <div class="pr-3 pl-3">
                    <Link to={`/business-loans/${node.slug}`} style={{textDecoration: "none"}}>
                        <button className=" w-full border-none mt-3 mb-3 p-3 bg-green-600 hover:bg-green-800 text-white font-bold text-center cursor-pointer">
                            Learn More
                        </button>
                    </Link>
                    </div>
                </div>
                )
              })}
          </div>
            </div>
         </div>
    );

    return (
      <Layout location={this.props.location} apply={""} mainContent={mainContent} title = {"Business Loans"} image={require("../assets/img/3.jpg")} sideContent={<SideContent articles={articles} />}>
      
          <Helmet title={siteTitle} />
      </Layout>
    )
  }
}

export default BusinessLoan

export const pageQuery = graphql`
  query BusinessLoan{
    allContentfulLoan{
      edges {
        node {
          title : name
          description {
            description
          }
          
          slug
          
        }
      }
      }
      allContentfulArticle(limit: 8){
      edges {
        node {
          title
          description {
            description
          }
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          image {
            file {
                url
            }
}
        }
      }
    }
    
  }`